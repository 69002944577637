import React, { useContext, useEffect, useState } from 'react'
import img from "../../images/assistant.png"
import HelpModal from './HelpModal'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import OnBoarding from './OnBoarding';

const AdminTopHeader = observer(() => {
    const {app} = useContext(Context);

    const [selectView, setSelectView] = useState('site');
    const [show, setShow] = useState();

    const [editMode, setEditMode] = useState();

    const [onboardingActive, setOnBoardingActive] = useState();

    useEffect(() => {
        if(document.body.className === "mob-site-visible"){
            setSelectView('mob-site-visible')
        }else if(document.body.className === "mob-app-visible"){
            setSelectView('mob-app-visible')
        }else{
            setSelectView('site')
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem('editMode')){
            setEditMode(true)
        }else{
            setEditMode(false)
        }
    }, [])

    const handleChange = () => {
        if(!editMode){
            localStorage.setItem('editMode', 'Режим редактирования');
        }else{
            localStorage.removeItem('editMode')
        }
        setEditMode(!editMode)
        app.setEditMode(!editMode)
    }

    const handleChangeView = (value) => {
        setSelectView(value);
        if(value === "site"){
            document.body.classList.remove('mob-app-visible')
            document.body.classList.remove('mob-site-visible');
            app.setMobView(false)
        }else if(value === "site_mob"){
            document.body.classList.remove('mob-app-visible');
            app.setMobView(true)
            document.body.classList.add('mob-site-visible');
        }else if(value === "mob_app"){
            document.body.classList.remove('mob-site-visible');
            document.body.classList.add('mob-app-visible')
            app.setMobView(false)
        }
    }

    const handleOnBoardingActive = () =>{
        if(!editMode){
            localStorage.setItem('editMode', 'Режим редактирования');
            app.setEditMode(!editMode)
            setEditMode(!editMode)
        }
        setOnBoardingActive(true)
    }

  return (
    <div className='admin-top-header'>
        <div className='container'>
            <div className='top-header-wrapper'>
                <div className='header-tabs'>
                    <div className='header-tabs-item active'>
                        {app.localizationsItems?.site}
                    </div>
                    <div className='header-tabs-item'>
                        <a href="/admin" target='blank'>{app.localizationsItems?.admin_panel}</a>
                    </div>
                </div>
                <div className='top-header-flex'>
                    <div className={selectView === 'site' ? 'header-btn active' : 'header-btn'} onClick={() => handleChangeView('site')}>
                        <i className='icon-airplay'></i>
                    </div>
                    <div className={selectView === 'site_mob' ? 'header-btn active' : 'header-btn'} onClick={() => handleChangeView('site_mob')}>
                        <i className='icon-ad_units'></i>
                    </div>
                    <div className={selectView === 'mob_app' ? 'header-btn active' : 'header-btn'} onClick={() => handleChangeView('mob_app')}>
                        <i className='icon-app_promo'></i>
                    </div>
                    <div className='header-line'></div>
                    <div className='fs-18'>{app.localizationsItems?.edit_mode}</div>
                    <div className='admin-ckheckout'>
                        <div className="form-check form-switch">
                            <div 
                                className={editMode ? "form-check-input active" : "form-check-input"}
                                onClick={handleChange}
                            />
                        </div>
                    </div>
                    <div className='btn-question' onClick={handleOnBoardingActive}>
                        <i className='icon-question'></i>
                    </div>
                </div>
            </div>
        </div>
        <div className='assistant' onClick={() => setShow(true)}>
            <div className='assistant-text'>{app.localizationsItems?.nothing_is_clear}</div>
            <div className='assistant-img'>
                <img src={img} alt="" />
            </div>
        </div>
        {show && <HelpModal show={show} setShow={setShow}/>}
        {onboardingActive && <OnBoarding onboardingActive={onboardingActive} setOnBoardingActive={setOnBoardingActive}/>}
    </div>
  )
})

export default AdminTopHeader