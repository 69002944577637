import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Context } from '../..';
import { Modal } from 'react-bootstrap'
import appStore from "../../images/appstore.png"
import playMarket from "../../images/googleplay.png"

const PopupModal = observer(({
    show, 
    setShow,
    appStoreLink,
    googlePlayLink
}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className='popup-modal-content'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className="popup-modal">
                <div>
                    <h3>{app.localizationsItems?.application_modal_title}</h3>
                    <p className='subtitle'>{app.localizationsItems?.application_modal_content}</p>
                    <div className="description-flex">
                        {appStoreLink && <a href={appStoreLink} target="_blank" rel="noopener">
                            <img src={appStore} alt=""/>
                        </a>}
                        {googlePlayLink && <a href={googlePlayLink} target="_blank" rel="noopener">
                            <img src={playMarket} alt=""/>
                        </a>}
                    </div>
                    <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.thanks}</button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default PopupModal