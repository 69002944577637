import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import { NavLink } from 'react-router-dom';

const BottomBar = observer(() => {
    const {app} = useContext(Context);
    const [socials, setSocials] = useState();

    useEffect(() => {
        if(app.settings){
			setSocials(app.settings.find(it => it.type === "socials"));
        }
    }, [app.settings])

  return (
    <div className='bottom-bar'>
        <div className='container'>
            <div className='bottom-bar-wrapper'>
                <p className='bottom-title'>{app.localizationsItems?.footer_bottom_title}</p>
                {socials?.value.find(el => el.value) &&
                    <div>
                        <nav className='socials-nav'>
                            {socials.value.map((social, index) =>
                                social.value &&
                                    <NavLink to={social.value} target="_blank" key={index}>
                                        <i className={`icon-${social.type}`}></i>
                                    </NavLink>
                            )}
                        </nav>
                    </div>
                }
            </div>
        </div>
    </div>
  )
})

export default BottomBar