import React, {useEffect, useState} from 'react';
import Select from "react-select";
//import {useTranslation} from "react-i18next";

export const stylesSelect = {
    control: (provided, state) => ({
        ...provided,
        height:'44px',
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: 'inherit',
        backgroundColor: state.isDisabled ? '#F4F5F7' : 'transparent',
        boxShadow: 'none',
        border: state.selectProps.$error ? '2px solid red' : (state.isFocused ? '2px solid #442EC8' : '2px solid #E7E7E7'),
        '&:hover': {
            borderColor: state.selectProps.$error ? 'red' : (state.isFocused ? '#442EC8' : '#9D9D9D'),
        }
    }),
    menu: provided => ({...provided, zIndex: 9999}),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0.175rem 2.25rem 0.175rem 0.75rem'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'inherit'
    }),
    multiValue: (provided) => ({
        ...provided,
        padding: '2px 4px',
        backgroundColor: '#eee'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: '14px'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer'
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        backgroundColor: state.isSelected ? '#e9ecef' : 'transparent',
        color: state.isSelected ? 'rgba(98, 89, 202, 0.8)' : '#666666',

        '&:hover': {
            backgroundColor: 'rgba(98, 89, 202, 0.16)',
            color:'rgba(98, 89, 202, 0.8)'
        }
    }),
};

const CustomSelect = ({onChange, options: passedOptions, changeCallback, isDisabled, value: passedValue, ...props}) => {
    //const {t} = useTranslation();
    const [value, setValue] = useState();
    const [options, setOptions] = useState();
    const {isMulti} = props;

    const handleChange = (data) => {
        if (isMulti) {
            let transformedData = data && data.length ? data?.map(item => item.value) : null;
            onChange(transformedData);

            if (changeCallback) {
                changeCallback(transformedData);
            }
        } else {
            onChange(data?.value ? data?.value : null);

            if (changeCallback) {
                changeCallback(data?.value ? data?.value : null);
            }
        }
    }

    useEffect(() => {
        if (passedOptions) {
            const newOptions = passedOptions?.map(item => {
                return {label: item.label, value: item.value}
            });
            setOptions(newOptions);
        }

        if (passedValue) {
            if (isMulti) {
                setValue(passedOptions ? passedOptions.filter(item => passedValue.includes(item.value)) : '');
            } else {
                setValue(passedOptions ? passedOptions.find(item => item.value == passedValue) : '')
            }
        } else {
            setValue(null)
        }
    }, [passedValue, isMulti, passedOptions]);

    return (
        <Select isClearable options={options} value={value ? value : ''} className='select-custom'
                placeholder={props.placeholder ? props.placeholder : 'Выбрать'} isDisabled={isDisabled}
                onChange={handleChange} {...props} noOptionsMessage={() => 'Нет данных'}
                loadingMessage={() => "Загрузка"} styles={stylesSelect}/>
    );
};

export default CustomSelect;