import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class ChatServices {
    static index = (queryString) => {
        return getData(http, `/app/chats${queryString ? `?${queryString}` : ""}`);
    }

    static view = (id, queryString) => {
        return getData(http, `/app/chats/${id}${queryString ? `${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/app/chats/store`, data);
    }

    static update = (id, data) => {
        return postData(http, `/app/chats/${id}`, data);
    }

    static delete = (id) => {
        return deleteData(http, `/app/chats/${id}`);
    }

    static messages = {
        send: (data) => {
            return postData(http, `/app/chats/msg/send`, data);
        }, 
        delete: (subid) => {
            return deleteData(http, `/app/chats/msg/${subid}`);
        }, 
    }
}