import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { Context } from "../..";
import CatalogServices from "../../services/general/catalogServices";
import AdminCatalogServices from "../../services/admin/catalogServices";
import SkeletonLoading from "../simple/SkeletonLoading";
import CartModal from "../modals/CartModal";
import ProductCard from "../simple/ProductCard";
import ProductEditCard from "./ProductEditCard";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick.hook";
import ResizebaleInput from "./ResizebleInput";

export const SortableProductGrid = observer(({
    item, 
    length, 
    index, 
    loading, 
    setLoading,
}) => {
    const {app, catalog} = useContext(Context);
    const [items, setItems] = useState();

    const [cartOpen, setCartOpen] = useState();

    const [presentPromocode, setPresentPromocode] = useState();
    const [deliveryPromocode, setDeliveryPromocode] = useState();
 
    const [deleteModal, setDeleteModal] = useState();

    const [isPublished, setIsPublished] = useState(item.is_published);

    const dropdownRefMenu = useRef();
    const [isDropdownActive, setIsDropdownActive] = useDetectOutsideClick(dropdownRefMenu, false);

    const fetchProducts = async () => {
        let str = `category_id=${item.id}`;

        const res = await CatalogServices.products.index(`${str}&per_page=all&show_unpublished=true`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }

        if(index === length-1){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(app.promocodes){
            setPresentPromocode(app.promocodes.find(it => it.type?.slug === "additional_product_wout_code"));
            setDeliveryPromocode(app.promocodes.find(it => it.type?.slug === "delivery_wout_code"));
        }
    }, [app.promocodes])

    useEffect(() => {
        fetchProducts()
    }, [])

    const handleDeleteCategories = () => {
        const tempArr = catalog.categories.filter(it => it.id !== item.id)
        catalog.setCategories(tempArr);
    }

    const changePublication = async () => {
        setIsPublished(!isPublished);
        const newData = {
            is_published: !isPublished
        }
        const res = await AdminCatalogServices.categories.updateSingle(item.id, newData);

        if(res.statusCode !== 200){
            setIsPublished(isPublished)
        }
    }

    const handleMove = async (dirName) => {
        const newItems = [...catalog.categories];
        const tempArr = [...catalog.categories]
        
        if(dirName === "up" && index !== 0){
            [newItems[index], newItems[index-1]] = [newItems[index-1], newItems[index]];
        }else if(dirName === "down" && index !== length-1){
            [newItems[index], newItems[index+1]] = [newItems[index+1], newItems[index]];
        }
        catalog.setCategories(newItems);

        if((dirName === "up" && index !== 0) || (dirName === "down" && index !== length-1)){
            const newData = {
                dir: dirName
            }
            const res = await AdminCatalogServices.categories.move(item.id, newData)
            
            if(res.statusCode !== 200){
                toast.error(res.message);
                catalog.setCategories(tempArr)
            }
        }
    }

    const createProduct = async () => {
        const res = await AdminCatalogServices.products.store({parent_id: item.id});
        if(res.statusCode === 200){
            fetchProducts();
            toast.success(res.message);
        }else{
            toast.error(res.message)
        }
        setIsDropdownActive(false);
    }

    const createCategories = async () => {
        const res = await AdminCatalogServices.categories.store({after: item.id});
        
        if(res.statusCode === 200){
            //fetchItems();
            toast.success(res.message);
        }else{
            toast.error(res.message)
        }
        setIsDropdownActive(false);
    }

    const handleUpdate = async (value) => {
        const place = {
            after_id: value.item.nextSibling?.id,
            category_id: value.to?.id
        }

        const res = await AdminCatalogServices.products.place(value.item.id, place);

        if(res.statusCode !== 200){
            toast.error(res.message)
        }

    }

    const handleAdd = async (value) => {
        const place = {
            after_id: items[value.newIndex]?.id,
            category_id: value.to?.id
        }

        const res = await AdminCatalogServices.products.place(value.item.id, place);

        if(res.statusCode !== 200){
            toast.error(res.message)
        }
    }

    const changeTitle = async (name) => {
        const newData = {
            title: name
        }
        const res = await AdminCatalogServices.categories.updateSingle(item.id, newData);
    }
  return (
    <>
        {loading ?
            <SkeletonLoading/>
            :
            <div className='mb-50 category-item' id={item.slug}>
                <div className='category-item-top mb-30'>
                    <ResizebaleInput title={item.title} changeTitle={changeTitle} isPublished={isPublished}/>
                    <div className="category-item-btns">
                        <div className="category-item-btns-item" ref={dropdownRefMenu} onClick={() => setIsDropdownActive(!isDropdownActive)}><i className="icon-plus"></i>
                            <div className={isDropdownActive ? "category-dropdown active" : "category-dropdown"}>
                                <div className="category-dropdown-item" onClick={createProduct}>{app.localizationsItems?.product}</div>
                                <div className="category-dropdown-item" onClick={createCategories}>{app.localizationsItems?.category}</div>
                            </div>
                        </div>
                        <div className="category-item-btns-item" onClick={changePublication}>
                            <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                        </div>
                        <div className="category-item-btns-item" onClick={() => handleMove('down')}><i className="icon-arrow_downward"></i></div>
                        <div className="category-item-btns-item" onClick={() => handleMove('up')}><i className="icon-arrow_upward"></i></div>
                        <div className="category-item-btns-item" onClick={() => setDeleteModal(true)}><i className="icon-trash"></i></div>
                    </div>
                </div>
                {items &&
                    <ReactSortable
                        list={items}
                        setList={setItems}
                        group="shared-group"
                        className="grid"
                        onUpdate={handleUpdate}
                        id={item.id}
                        onAdd={handleAdd}
                        //onEnd={handleAdd}
                    >
                        {items.map((card, index) =>
                            <ProductEditCard 
                                card={card} 
                                key={card.id} 
                                setCartOpen={setCartOpen}
                                fetchProducts={fetchProducts}
                                items={items}
                                index={index}
                            />
                        )}
                    </ReactSortable>
                }
                {cartOpen && 
                    <CartModal 
                        show={cartOpen} 
                        setShow={setCartOpen} 
                        deliveryPromocode={deliveryPromocode} 
                        presentPromocode={presentPromocode}
                    />
                }
                {deleteModal &&
                    <DeleteModal
                        show={deleteModal}
                        setShow={setDeleteModal}
                        data={item.id}
                        method={AdminCatalogServices.categories.delete}
                        successCallback={handleDeleteCategories}
                    />
                }
            </div>
        }
    </>
  );
});