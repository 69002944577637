import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Dictionaries from '../../services/admin/dictionaries';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import CatalogServices from '../../services/admin/catalogServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UploadImg from './UploadImg';
import ModalDiscount from './ModalDiscount';
import CustomSelect from '../ui/CustomSelect';

const ProductEditModal = observer(({show, setShow, card, fetchProducts}) => {
    const {app, catalog} = useContext(Context);

    const handleClose = () => setShow(false);

    const [productStates, setProductStates] = useState();

    const [states, setStates] = useState();

    const [discount, setDiscount] = useState();

    const [showDiscountModal, setShowDiscountModal] = useState()

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const [categories, setCategories] = useState();

    useEffect(() => {
        if(card){
            setValue('title', card.title);
            setValue('description', card.description);
            setValue('price', card.price);
            setValue('subtitle', card.subtitle);
            setValue('image', card.image);
            setValue('availability', card.availability);
            setValue('is_published', card.is_published);
            card.parent && setValue('parent_id', card.parent.id);
            card.states && setStates(card.states)
        }
    }, [card])

    const fetchProductStates = async () => {
        try {
            const res = await Dictionaries.productStates();

            setProductStates(res.content);
        } catch (e) {
            toast.error(e.message)
        }
    }

    useEffect(() => {
        fetchProductStates()
    }, [])

    useEffect(() => {
        if(catalog.categories){
            let temp = catalog.categories.map((it => {
                return {label: it.title, value: it.id}
            }))
            setCategories(temp)
        }
    }, [catalog.categories])

    const changeStates = (el) => {
        let temp = []
        if(states.find(it => it.id == el.id)){
            temp = states.filter(it => it.id !== el.id);
        }else{
            temp = [...states, el]
        }
        setStates(temp);
    }

    const customSubmit = async (data) => {
        const tempStates = states.map(it => it.id);

        const {number, type, ...rest} = data;

        const tempDiscount = {
            number, 
            type,
            ...discount
        }
        
        const newData = {
            states: tempStates,
            discount: tempDiscount,
            ...rest
        }

        const res = await CatalogServices.products.update(card.id, newData);

        if(res.statusCode === 200){
            fetchProducts();
            setShow(false);
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='admin-modal'>
        <div className='admin-modal-top'>
            <h2>{app.localizationsItems?.edit_product}</h2>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
        </div>
        <Modal.Body>
            <form className='form-wrapper' onSubmit={handleSubmit(customSubmit)}>
                <Controller
                    control={control}
                    name="image"
                    render={({field: {onChange, value}}) => (
                        <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                    )}
                />
                <div className='admin-label'>
                    {app.localizationsItems?.category}
                    {categories && 
                        <Controller name="parent_id" control={control}
                            render={({field: {onChange, value}}) => (
                                <CustomSelect options={categories} 
                                    onChange={onChange} 
                                    placeholder='Выберите категорию'
                                    value={value}
                                />
                            )}
                        />
                    }
                </div>
                <div className='admin-label'>
                    {app.localizationsItems?.naiming}
                    <input {...register('title')} type="text" className='admin-input' placeholder={app.localizationsItems?.naiming}/>
                </div>
                <div className='admin-label'>
                    {app.localizationsItems?.description}
                    <textarea {...register('description')} className='admin-input textarea' placeholder={app.localizationsItems?.enter_text}></textarea>
                </div>
                <div className='admin-label'>
                    {app.localizationsItems?.price}
                    <input type="text" {...register('price')} className='admin-input' placeholder={app.localizationsItems?.price}/>
                </div>
                <div className='admin-label'>
                    {app.localizationsItems?.discount}
                    <div className='admin-discount'>
                        <input type="text" className='admin-input' {...register('number')} placeholder='0'/>
                        <select className='admin-select' {...register('type')}>
                            <option>%</option>
                            <option>₸</option>
                        </select>
                        <div className='admin-input center' onClick={() => setShowDiscountModal(true)}>
                            Срок
                        </div>
                    </div>
                </div>
                <div className='admin-label'>
                    {app.localizationsItems?.weight}
                    <input type="text" {...register('subtitle')} className='admin-input' placeholder={app.localizationsItems?.weight}/>
                </div>
                {productStates &&
                    <div className='product-states'>
                        {productStates?.map((el, index) =>
                            <div className='product-states-item' 
                                key={index}
                                style={states?.find((it => it.id == el.id)) && {color: '#fff', background: el.subtitle}}
                                onClick={() => changeStates(el)}
                            >
                                {el.name}
                            </div>
                        )}
                    </div>
                }
                <div className='admin-flex'>
                    {app.localizationsItems?.published}
                    <div className='admin-ckheckout'>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" {...register('is_published')}/>
                        </div>
                    </div>
                </div>
                <div className='admin-flex'>
                    {app.localizationsItems?.available_for_order}
                    <div className='admin-ckheckout'>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" {...register('availability')}/>
                        </div>
                    </div>
                </div>
                <button className='admin-btn'>{app.localizationsItems?.save}</button>
            </form>
            {showDiscountModal && 
                <ModalDiscount 
                    show={showDiscountModal} 
                    setShow={setShowDiscountModal}
                    discount={discount}
                    setDiscount={setDiscount}
                />
            }
        </Modal.Body>
    </Modal>
  )
})

export default ProductEditModal