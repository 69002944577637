import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { Context } from '../..';
import { observer } from 'mobx-react-lite'

const DeleteModal = observer(({
    show,
    setShow,
    method,
    data,
    successCallback
}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        const res = await method(data);

        if(res.statusCode === 200){
            toast.success(res.message);
            handleClose();
            successCallback();
        }else{
            toast.error(res.message)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='admin-modal'>
        <div className='admin-modal-top'>
            <h2>{app.localizationsItems?.delete_item}</h2>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
        </div>
        <Modal.Body>
            <p className='mb-30'>{app.localizationsItems?.deleted_forever}</p>
            <div className='btns-flex'>
                <div className='admin-btn' onClick={handleClose}>{app.localizationsItems?.not_delete}</div>
                <div className='admin-btn-outline' onClick={handleDelete}>{app.localizationsItems?.delete_item}</div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default DeleteModal