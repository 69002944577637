import React, { useContext, useEffect, useState } from 'react'
import AdminTopHeader from '../../components/admin/AdminTopHeader'
import Header from '../../components/complex/Header'
import AdminProductsGrid from '../../components/admin/AdminProductsGrid'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../..'
import MainSlider from '../../components/complex/MainSlider'
import AdminStoriesComponent from '../../components/admin/AdminStoriesComponent'
import AppMobBanner from '../../components/admin/AppMobBanner'
import PopularProducts from '../../components/complex/PopularProducts'
import AppAdvertisingBanner from '../../components/complex/AppAdvertisingBanner'
import AdminPopularProducts from '../../components/admin/AdminPopularProducts'

const AdminMainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    const [promoBigImage, setPromoBigImage] = useState();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    useEffect(() => {
        if(app.settings){
            setPromoBigImage(app.settings.find(it => it.type === "promo_big_image")?.value);
        }
    }, [app.settings])

  return (
    <>
        <AppMobBanner/>
        <div className='site-wrapper'>
            {promoBigImage ?
                <div className='mb-50'>
                    <MainSlider/>
                </div>
                :
                <div className='mb-50' id='stories-banner'>
                    <AdminStoriesComponent/>
                </div>
            }
            <div className='mb-50'>
                <AdminPopularProducts/>
            </div>
            <div className='mb-50'>
                <AdminProductsGrid/>
            </div>
            <AppAdvertisingBanner/>
        </div>
    </>
  )
})

export default AdminMainPage