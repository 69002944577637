import React, { useContext, useEffect, useState } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import ProductCard from '../simple/ProductCard';
import SkeletonLoading from '../simple/SkeletonLoading';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CartModal from '../modals/CartModal';

const CategoryProducts = observer(({
    item, 
    length, 
    index, 
    loading, 
    setLoading
}) => {
    const {app} = useContext(Context);
    const [items, setItems] = useState();

    const [cartOpen, setCartOpen] = useState();

    const fetchProducts = async () => {
        let str = `category_id=${item.id}`;

        const res = await CatalogServices.products.index(`${str}&per_page=all`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }

        if(index === length-1){
            setLoading(false);
        }
    }

    const [deliveryPromocode, setDeliveryPromocode] = useState();
    const [presentPromocode, setPresentPromocode] = useState();

    useEffect(() => {
        if(app.promocodes){
            setPresentPromocode(app.promocodes.find(it => it.type?.slug === "additional_product_wout_code"));
            setDeliveryPromocode(app.promocodes.find(it => it.type?.slug === "delivery_wout_code"));
        }
    }, [app.promocodes])

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    <>
        {loading ?
            <SkeletonLoading/>
            :
            <div className='mb-50 category-item' id={item.slug}>
                <h2 className='mb-30'>{item.title}</h2>
                <div className='grid'>
                    {items?.map((card, index) =>
                        <ProductCard card={card} key={index} setCartOpen={setCartOpen}/>
                    )}
                </div>
                {cartOpen && <CartModal show={cartOpen} setShow={setCartOpen} deliveryPromocode={deliveryPromocode} presentPromocode={presentPromocode}/>}
            </div>
        }
    </>
  )
})

export default CategoryProducts