import http from "./http-common"
import {deleteData, getData, postData, putData} from "../handlers";

export default class MarketingServices {
    static promoCodes = {
        index: (queryString) => {
            return getData(http, `/promoCodes${queryString ? `?${queryString}` : ""}`);
        },
        update: (id, data) => {
            return postData(http, `/promoCodes/${id}`, data);
        },
        store: (data) => {
            return postData(http, `/promoCodes/store`, data);
        }, 
        delete: (id) => {
            return deleteData(http, `/promoCodes/${id}`);
        },
        updateSingle: (id, data) => {
            return putData(http, `/promoCodes/${id}`, data);
        },
        move: (id, data) => {
            return postData(http, `/promoCodes/${id}/move`, data);
        },
    }
}