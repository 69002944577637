import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import CartModal from '../modals/CartModal'
import UserServices from '../../services/general/userServices'
import { toast } from 'react-toastify'
import ScrollCategories from './ScrollCategories'
import MobileFixMenu from '../complex/MobileFixMenu'
import DeliveryStock from './DeliveryStock'
import PresentStock from './PresentStock'
import Utils from '../../services/utils'

const Menu = observer(() => {
    const {app, user} = useContext(Context);

    const [logo, setLogo] = useState();
    const [show, setShow] = useState();
    const [sticky, setStiky] = useState();

    const [deliveryPromocode, setDeliveryPromocode] = useState();
    const [presentPromocode, setPresentPromocode] = useState();

    const [total, setTotal] = useState();

    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo")?.value);
        }
    }, [app.settings])

    useEffect(() => {
        if(app.promocodes){
            setPresentPromocode(app.promocodes.find(it => it.type?.slug === "additional_product_wout_code"));
            setDeliveryPromocode(app.promocodes.find(it => it.type?.slug === "delivery_wout_code"));
        }
    }, [app.promocodes])

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
            }
            setTotal(temp);
        }
    }, [user.cartProducts])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const handleShow = () => {
        setShow(true)
    };

    const fetchItems = async () => {
        const res = user.uid ? await UserServices.cart.index(user.uid) : await UserServices.cart.index();

        if(res.statusCode === 200){
            res.content?.products ? user.setCartProducts(res.content.products) : user.setCartProducts([])
            res.content?.total_price ? user.setTotalSum(res.content.total_price) : user.setTotalSum(0)

            if(res.content?.uid && (res.content?.uid !== user.uid)){
                user.setUid(res.content?.uid)
            }
        }
    }

    useEffect(() => {
        fetchItems();
    }, [user.basketChange])

  return (
    <>
        <div className={sticky ? 'menu sticky' : 'menu'}>
            <div className="container">
                <div className="flex">
                    <ScrollCategories logo={logo}/>
                    <div className='buttons-flex'>
                        {presentPromocode && <PresentStock presentPromocode={presentPromocode}/>}
                        {deliveryPromocode && <DeliveryStock deliveryPromocode={deliveryPromocode}/>}
                        <button className='main-btn btn-cart' onClick={handleShow} id='cart-button'>
                            <i className='icon-cart'></i>
                            {total > 0 ?
                                <div className='vis-none'>
                                    {Utils.numberWithSpaces(total)} {app.localizationsItems?.currency} <span className='count'>|</span>
                                </div>
                                :
                                <div className='vis-none'>
                                    {app.localizationsItems?.cart} <span className='count'>|</span>
                                </div>
                            }
                            <span className='count count-icon'>{user.cartProducts?.length ? user?.cartProducts?.length : 0}</span>
                        </button>
                    </div>
                </div>
            </div>
            {show && <CartModal show={show} setShow={setShow} deliveryPromocode={deliveryPromocode} presentPromocode={presentPromocode}/>}
        </div>
        <MobileFixMenu setShow={setShow} total={total}/>
    </>
  )
})

export default Menu