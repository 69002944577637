import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';

const days = [
    {title: 'Понедельник', value: 'monday'},
    {title: 'Вторник', value: 'tuesday'},
    {title: 'Среда', value: 'wednesday'},
    {title: 'Четверг', value: 'thursday'},
    {title: 'Пятница', value: 'friday'},
    {title: 'Суббота', value: 'saturday'},
    {title: 'Воскресенье', value: 'sunday'},
]

const ModalDiscount = observer(({
    show,
    setShow,
    setDiscount
}) => {
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);

    const {register, control, setValue, getValues, handleSubmit, formState: {errors}} = useForm();

    const customSubmit = async () => {
        const data = getValues();

        const {monday, tuesday, wednesday, thursday, friday, saturday, sunday,
            mondaybegin0, mondayend0, tuesdaybegin1, tuesdayend1, wednesdaybegin2, wednesdayend2, 
            thursdaybegin3, thursdayend3, fridaybegin4, fridayend4, saturdaybegin5, saturdayend5, 
            sundaybegin6, sundayend6, ...rest} = data;

        const newData = {
            weekdays:[
                        monday && {
                            day: "monday",
                            begin: mondaybegin0,
                            end: mondayend0,
                        },
                        tuesday && {
                            day: "tuesday",
                            begin: tuesdaybegin1,
                            end: tuesdayend1
                        },
                        wednesday && {
                            day: "wednesday",
                            begin: wednesdaybegin2,
                            end: wednesdayend2
                        },
                        thursday && {
                            day: "thursday",
                            begin: thursdaybegin3,
                            end: thursdayend3
                        },
                        friday && {
                            day: "friday",
                            begin: fridaybegin4,
                            end: fridayend4
                        },
                        saturday && {
                            day: "saturday",
                            begin: saturdaybegin5,
                            end: saturdayend5
                        },
                        sunday && {
                            day: "sunday",
                            begin: sundaybegin6,
                            end: sundayend6
                        },
            ].filter(Boolean),
            ...rest
        }
        setDiscount(newData);
        setShow(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='admin-modal wd-540'>
        <div className='admin-modal-top'>
            <h2>{app.localizationsItems?.discount}</h2>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
        </div>
        <Modal.Body>
            <form className='form-wrapper'>
                <div className='discount-modal-flex'>
                    <span>{app.localizationsItems?.validity_period}:</span>
                    <div className='admin-discount'>
                        С<input type="date" className='admin-input' {...register('date_from')}/>
                        По<input type="date" className='admin-input' {...register('date_to')}/>
                    </div>
                </div>
                <div>{app.localizationsItems?.valid_according_to}:</div>
                {days?.map((card, index) =>
                    <div className='discount-modal-flex' key={index}>
                        <label className='product-checkbox'>
                            <input type="checkbox" {...register(card.value)}/><span>{card.title}:</span>
                        </label>
                        <div className='admin-discount'>
                            С<input type="time" className='admin-input' {...register(`${card.value}begin${index}`)}/>
                            По <input type="time" className='admin-input' {...register(`${card.value}end${index}`)}/>
                        </div>
                    </div>
                )}
                <div className='admin-btn' onClick={customSubmit}>{app.localizationsItems?.save}</div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default ModalDiscount