import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class CatalogServices {
    static categories = {
        index: () => {
            return getData(http, `/app/catalog/categories`);
        },
        index2: () => {
            return getData(http, `/app/catalog/categories?show_unpublished=true`);
        },
        indexIds: (queryString) => {
            let params = ''
            if(queryString){
                queryString.forEach(element => {
                    params += `ids[]=${element}&`
                });
            }
            return getData(http, `/app/catalog/categories/without-subcategories${params ? `?${params}` : ""}`);
        },
        view: (slug, queryString) => {
            return getData(http, `/app/catalog/categories/${slug}${queryString ? `?${queryString}` : ""}`);
        },
    }

    static products = {
        index: (queryString) => {
            return getData(http, `/app/catalog/products${queryString ? `?${queryString}` : ""}`);
        },

        suggest: (slug, queryString) => {
            return getData(http, `/app/catalog/products/${slug}/suggest${queryString ? `?${queryString}` : ""}`);
        },

        latestProducts: () => {
            return getData(http, `/app/catalog/products`);
        },

        view: (slug, queryString) => {
            return getData(http, `/app/catalog/products/${slug}${queryString ? `?${queryString}` : ""}`);
        },
        place: (id, data) => {
            return postData(http, `/app/catalog/products/${id}/place`, data);
        },

    }
}