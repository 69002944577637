import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../..';
import CatalogServices from '../../services/general/catalogServices';
import { NavLink, useNavigate } from 'react-router-dom';

const ScrollCategories = observer(({logo}) => {
    const {app, catalog, user} = useContext(Context);
    const scrollContainerRef = useRef(null);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const navigate = useNavigate();

    const [activeSection, setActiveSection] = useState(null);

    const fetchCategories = async () => {
        const res = app.editMode ? await CatalogServices.categories.index2() : await CatalogServices.categories.index();

        if(res.statusCode === 200){
            res.content.items && catalog.setCategories(res.content.items);
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [app.editMode])

    const handleScrollToCategory = (category) => {
        navigate(`/${app.lang}`)

        var element = document.querySelector(category);
        var headerOffset = 63;
        if(element){
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }
  
    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };
  
    const handleMouseLeave = () => {
        setIsMouseDown(false);
    };
  
    const handleMouseUp = () => {
        setIsMouseDown(false);
    };
  
    const handleMouseMove = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 3;
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };  

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
        
            const sections = document.querySelectorAll('.category-item');

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if ((scrollPosition >= (sectionTop + sectionHeight)) && (scrollPosition < (sectionTop + sectionHeight + sectionHeight))) {
                    let menuItem = document.querySelector(`[data="${section.id}"]`);

                    scrollContainerRef.current.scrollTo({
                        left: menuItem?.offsetLeft,
                        behavior: "smooth"
                    });
                    setActiveSection(section.id);
                }
            });
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
    };

  return (
    <nav className='nav'
        ref={scrollContainerRef}
        onMouseDown={e => handleMouseDown(e)}
        onMouseLeave={e => handleMouseLeave(e)}
        onMouseUp={e => handleMouseUp(e)}
        onMouseMove={e => handleMouseMove(e)}
    >
        <NavLink to={`/${app.lang}`} className={'logo-sticky'} onClick={scrollToTop}>
            <img src={logo} alt="" />
        </NavLink>
        {user.favorites?.length >= 1 &&
            <div
                className={activeSection === `favorites` ? 'nav-item active' : 'nav-item'}
                data={`favorites`}
                onClick={() => handleScrollToCategory(`#favorites`)}
                >
                {app.localizationsItems?.wishlist}
            </div>
        }
        {catalog.categories?.map((card, index) =>
            <div key={index}
                className={activeSection === `${card.slug}` ? 'nav-item active' : 'nav-item'}
                onClick={() => handleScrollToCategory(`#${card.slug}`)}
                data={`${card.slug}`}
                >
                    {card.title}
            </div>
        )}
    </nav>
  )
})

export default ScrollCategories