import React, { useContext, useState } from 'react'
import MarketingServices from '../../services/admin/marketingSertives';
import DeleteModal from './DeleteModal';
import { toast } from 'react-toastify';
import img from "../../images/placeholder.png"
import StoriesEditModal from './StoriesEditModal';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const AdminStoryItem = observer(({card, visited, fetchItems}) => {
    const {app} = useContext(Context);
    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [isPublished, setIsPublished] = useState(card.is_published);

    const changePublished = async () => {
        setIsPublished(!isPublished);
        const newData = {
            is_published: !isPublished
        }
        const res = await MarketingServices.promoCodes.updateSingle(card.id, newData);
        if(res.statusCode !== 200){
            setIsPublished(isPublished);
            toast.error(res.message)
        }
    }

    const handleMove = async (dirName) => {
        const newData = {
            dir: dirName
        }

        const res = await MarketingServices.promoCodes.move(card.id, newData);

        if(res.statusCode === 200){
            fetchItems();
        }else{
            toast.error(res.message)
        }
    }

  return (
    <>
    <div
        className={visited.find(it => it === card.id) ? 'stories-item visited admin-stories-item' : 'stories-item admin-stories-item'} 
    >
        <div className={isPublished ? 'product-card-unpublished isPublished' : 'product-card-unpublished'} onClick={changePublished}>
            <i className={isPublished ? 'icon-visibility' : 'icon-visibility_off'}></i>
        </div>
        <div className='product-add-btn' onClick={() => handleMove('up')}>
            <i className='icon-chevron-left'></i>
        </div>
        <img src={card.preview_image ? card.preview_image : img} alt="" 
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src = img
            }}
        />
        <div className='product-add-btn right' onClick={() => handleMove('down')}>
            <i className='icon-chevron-right'></i>
        </div>
        <div className='admin-stories-item-content'>
            <div className='admin-stories-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                <i className='icon-pen'></i>
            </div>
            <div className='admin-stories-btn delete' onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}
                <i className='icon-trash'></i>
            </div>
        </div>
    </div>
    {showEdit && 
        <StoriesEditModal
            show={showEdit} 
            setShow={setShowEdit}
            story={card}
        />
    }
    {deleteModal &&
        <DeleteModal
            show={deleteModal}
            setShow={setDeleteModal}
            data={card.id}
            method={MarketingServices.promoCodes.delete}
            successCallback={fetchItems}
        />
    }
    </>
  )
})

export default AdminStoryItem