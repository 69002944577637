import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import UploadImg from './UploadImg';
import MarketingServices from '../../services/admin/marketingSertives';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const StoriesEditModal = observer(({show, setShow, story}) => {
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    useEffect(() => {
        setValue('preview_image', story.preview_image)
        setValue('detail_image', story.detail_image)
        setValue('is_published', story.is_published)
    }, [story])

    const customSubmit = async (data) => {
        const res = await MarketingServices.promoCodes.update(story.id, data);

        if(res.statusCode === 200){
            setShow(false)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='admin-modal wd-540'>
        <div className='admin-modal-top'>
            <h2>{story.title}</h2>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
        </div>
        <Modal.Body>
            <form className='form-wrapper' onSubmit={handleSubmit(customSubmit)}>
                <div className='stories-flex'>
                    <div>
                        <div className='mb-10'>{app.localizationsItems?.mini_image}</div>
                        <Controller
                            control={control}
                            name="preview_image"
                            render={({field: {onChange, value}}) => (
                                <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                            )}
                        />
                    </div>
                    <div>
                        <div className='mb-10'>{app.localizationsItems?.big_image}</div>
                        <Controller
                            control={control}
                            name="detail_image"
                            render={({field: {onChange, value}}) => (
                                <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                            )}
                        />
                    </div>
                </div>
                <div className='admin-flex'>
                    {app.localizationsItems?.published}
                    <div className='admin-ckheckout'>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" {...register('is_published')}/>
                        </div>
                    </div>
                </div>
                <button className='admin-btn'>{app.localizationsItems?.save}</button>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default StoriesEditModal