import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import { SortableProductGrid } from './SortableProductGrid'
import CatalogServices from '../../services/general/catalogServices'
import SkeletonLoading from '../simple/SkeletonLoading'

const AdminProductsGrid = observer(() => {
    const {user, catalog} = useContext(Context);
    const componentRef = useRef(null);
    const [isFixed, setIsFixed] = useState(false);

    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);
    const [categoryLoading, setCategoryLoading] = useState(true);

    const fetchItems = async () => {
        const res = await CatalogServices.categories.index2();

        if(res.statusCode === 200 && res.content){
            setItems(res.content.items)
        }
        setCategoryLoading(false)
    }
    
    useEffect(() => {
        fetchItems();
    }, [])
    
    const handleScroll = () => {
        if (componentRef.current) {
          const { top } = componentRef.current.getBoundingClientRect();
          setIsFixed(top <= 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className='container' ref={componentRef}>
        {categoryLoading ?
            <SkeletonLoading/>
            :
            items?.map((item, index) =>
                <SortableProductGrid 
                    key={item.id} 
                    item={item} 
                    length={catalog.categories.length} 
                    index={index} 
                    loading={loading} 
                    setLoading={setLoading}
                />
            )
        }
    </div>
  )
})

export default AdminProductsGrid