import {makeAutoObservable} from "mobx";

export default class CatalogStore {
    constructor() {
        this._searchQuery = ''
        this._categories = null
        this._statuses = [
            {name: "Новый", slug: "order_new"},
            {name: "Принят", slug: "order_accepted"},
            {name: "Формируется", slug: "order_forming"},
            {name: "Доставляется", slug: "order_delivering"},
        ]

        makeAutoObservable(this)
    }

    setSearchQuery(searchQuery) {
        this._searchQuery = searchQuery
    }

    setStatuses(statuses) {
        this._statuses = statuses
    }

    setCategories(categories) {
        this._categories = categories
    }

    get searchQuery() {
        return this._searchQuery
    }

    get statuses() {
        return this._statuses
    }

    get categories() {
        return this._categories
    }

}