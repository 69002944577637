import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick';
import StoriesLoading from '../simple/StoriesLoading';
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import StoriesModal from '../modals/StoriesModal';
import StoriesEditModal from './StoriesEditModal';
import DeleteModal from './DeleteModal';
import MarketingServices from '../../services/admin/marketingSertives';
import { toast } from 'react-toastify';
import AdminStoryItem from './AdminStoryItem';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const AdminStoriesComponent = observer(() => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();
    const [showEdit, setShowEdit] = useState();
    const [currentStory, setCurrentStory] = useState(0);

    const [loading, setLoading] = useState(true);
    const [visited, setVisited] = useState([]);

    const [stories, setStories] = useState();

    const [slidesToShow, setSlidesToShow] = useState(5)

    const {
        items,
        fetchItems
    } = useFetchItems(MarketingServices.promoCodes.index)

    useEffect(() => {
        if(app.mobView){
            setSlidesToShow(4)
        }else{
            setSlidesToShow(5)
        }
    }, [app.mobView]);
    
    useEffect(() => {
        if(items?.items){
            const localVisitedStoriesArr = localStorage.getItem("stories_opened") ? JSON.parse(localStorage.getItem("stories_opened")) : [];
            const actualVisitedStoriesArr = localVisitedStoriesArr.filter(item => items.items.find(it => it.id === item))

            if(localVisitedStoriesArr !== actualVisitedStoriesArr){
                localStorage.setItem('stories_opened', JSON.stringify(actualVisitedStoriesArr));
            }
            setVisited(actualVisitedStoriesArr);
            setLoading(false);

            //let temp = items.items.filter(it => it.preview_image)
            setStories(items.items)
        }
    }, [items])

    const handleCreate = async() => {
        const res = await MarketingServices.promoCodes.store();

        if(res.statusCode === 200){
            toast.success(res.message);
            fetchItems();
        }else{
            toast.error(res.message)
        }
    }
    
  return (
    <>
        {loading ?
            <StoriesLoading/>
            :
            <div className='container'>
                <div className='stories'>
                    <Slider className='stories-slider'
                        arrows={true}
                        infinite={false}
                        slidesToShow={slidesToShow}
                        slidesToScroll={1}
                        responsive= {[{
                                breakpoint: 1250,
                                settings: {
                                    slidesToShow: 4,
                                }
                            },
                            {
                                breakpoint: 996,
                                settings: {
                                    slidesToShow: 5,
                                }
                            },
                            {
                                breakpoint: 886,
                                settings: {
                                    slidesToShow: 4,
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 5,
                                }
                            },
                            {
                                breakpoint: 576,
                                settings: {
                                    slidesToShow: 4,
                                }
                            },
                        ]}
                    >
                        {stories?.map((card, index) =>
                            <AdminStoryItem card={card} key={index} visited={visited} fetchItems={fetchItems}/>
                        )}
                        <div className='stories-item admin-stories-item center'>
                            <div className='story-content' onClick={handleCreate}>
                                Добавить
                                <i className='icon-plus'></i>
                            </div>
                        </div>
                    </Slider>
                </div>
                {show &&
                    <StoriesModal
                        show={show}
                        setShow={setShow}
                        stories={stories}
                        setVisited={setVisited}
                        currentStory={currentStory}
                    />
                }
            </div>
        }
    </>
  )
})

export default AdminStoriesComponent