import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import ProductsLoading from '../simple/ProductsLoading';
import RecommendedProductCard from '../simple/RecommendedProductCard';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const AdminPopularProducts = observer(() => {
    const {app} = useContext(Context);

    const { 
        items,
        loading
    } = useFetchItems(CatalogServices.products.index, `type=recomended`);

    const [slidesToShow, setSlidesToShow] = useState(6)

    useEffect(() => {
        if(app.mobView){
            setSlidesToShow(3)
        }else{
            setSlidesToShow(6)
        }
    }, [app.mobView]);

  return (
    <>
        {loading ?
            <ProductsLoading/>
            :
            <div>
                <div className="container">
                    <h2 className='mb-30'>{app.localizationsItems?.recomended_products}</h2>
                </div>
                {items?.items?.length >=1 && 
                    <div className="container">
                        <Slider className='recent-slider'
                            arrows={true}
                            infinite={false}
                            slidesToShow={slidesToShow}
                            slidesToScroll={1}
                            responsive={[
                                {
                                    breakpoint: 1600,
                                    settings: {
                                        slidesToShow: 6,
                                    }
                                },
                                {
                                    breakpoint: 1300,
                                    settings: {
                                        slidesToShow: 5,
                                    }
                                },
                                {
                                    breakpoint: 996,
                                    settings: {
                                        slidesToShow: 4,
                                    }
                                },
                                {
                                    breakpoint: 757,
                                    settings: {
                                        slidesToShow: 3,
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 3,
                                    }
                                },
                            ]}
                        >
                            {items.items.map((card, index) =>
                                <RecommendedProductCard card={card} key={index}/>
                            )}
                        </Slider>
                    </div>
                }
            </div>
        }
    </>
  )
})

export default AdminPopularProducts