import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import ProductViewModal from '../modals/ProductViewModal';
import UserServices from '../../services/general/userServices';
import img from "../../images/placeholder.png"
import ProductCardButton from '../simple/ProductCardButton';
import ProductEditModal from './ProductEditModal';
import DeleteModal from './DeleteModal';
import CatalogServices from '../../services/admin/catalogServices';
import { toast } from 'react-toastify';

const ProductEditCard = observer(({
    card, 
    setCartOpen, 
    fetchProducts,
    items,
    index
}) => {
    const {app, user} = useContext(Context);

    const [show, setShow] = useState();
    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();

    const [isFavorite, setIsFavorite] = useState();
    const [showRecomended, setShowRecomended] = useState();
    const [recomendedSlug, setRecomendedSlug] = useState();

    const [isPublished, setIsPublished] = useState(card.is_published);
    const [isAvailable, setIsAvailable] = useState(card.availability);

    useEffect(() => {
        if(user.favorites.find(it => it ===card.id)){
            setIsFavorite(true);
        }else{
            setIsFavorite(false);
        }
    }, [user.favorites, user.wishlistCountChange])

    const addToWishlist = async (e) => {
        e.stopPropagation();
        let temp = isFavorite;
        setIsFavorite(!isFavorite);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: card.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
            user.setWishlistCountChange(Math.random().toString(16))
        }else{
            setIsFavorite(temp)
        }
    }

    const openProduct = (slug) => {
        setRecomendedSlug(slug);
        setShowRecomended(true);
        setShow(false);
    }

    const changeAvailability = async () => {
        setIsAvailable(!isAvailable);
        const newData = {
            availability: !isAvailable
        }
        const res = await CatalogServices.products.updateSingle(card.id, newData);

        if(res.statusCode !== 200){
            setIsAvailable(isAvailable);
            toast.error(res.message)
        }
    }

    const changePublication = async () => {
        setIsPublished(!isPublished);
        const newData = {
            is_published: !isPublished
        }
        const res = await CatalogServices.products.updateSingle(card.id, newData);
        if(res.statusCode !== 200){
            setIsPublished(isPublished);
            toast.error(res.message)
        }
    }

    const createProduct = async (direction) => {
        let newData = {after: card.id}

        if(direction){
            if(index-1 > 0){
                newData = {after: items[index-1].id}
            }else{
                newData = {parent_id: card.parent?.id}
            }
        }

        const res = await CatalogServices.products.store(newData);

        if(res.statusCode === 200){
            fetchProducts();
            toast.success(res.message);
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div className='product-card product-card-hover' id={card.id}>
        {!isPublished && <div className='product-card-unpublished'>
            <i className='icon-visibility_off'></i>
        </div>}
        <div className='product-add-btn' onClick={() => createProduct('before')}>
            <i className='icon-plus'></i>
        </div>
        <div className='card-relative' onClick={() => setShow(true)}>
            <span className='to-wishlist'  onClick={e =>addToWishlist(e)}>
                <i className={isFavorite ? 'icon-like active' : 'icon-heart'}></i>
            </span>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={266}
                height={225}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
            <div className='modal-states'>
                {card.states?.map((it, index) =>
                    <div className="product-state" style={{backgroundColor: `${it.subtitle}`}} key={index}>
                        <label>{it.name}</label>
                    </div>
                )}
                {card.bonus && <div className="product-bonus">
                    <label>{card.bonus.number}{card.bonus.type === 1 ? '%' : ''} Б</label>
                </div>}
            </div>
        </div>
        <div className={!isPublished ? 'product-flex not-published' : 'product-flex'}>
            <div className='product-card-wrapper' onClick={() => setShow(true)}>
                <h4>{card.title}</h4>
                <div className='subtitle'>{card.subtitle}</div>
                <div className='content' dangerouslySetInnerHTML={{__html: card.description}}></div>
            </div>
            <div className='product-card-content'>
                <h4>
                    {card.old_price && <span className='old-price'>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
                    <span className='price'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} </span>
                </h4>
                <ProductCardButton info={card} setShow={setShow}/>
            </div>
        </div>
        <div className='product-card-hover-content'>
            <div className='product-card-btn change' onClick={changePublication}>
                <i className={isPublished ? 'icon-visibility' : 'icon-visibility_off'}></i>
            </div>
            <div className='product-card-btn change' onClick={changeAvailability}>
                <i className={isAvailable ? 'icon-shopping_cart_checkout' : 'icon-shopping_cart_off'}></i>
            </div>
            <div className='product-card-btn edit' onClick={() => setShowEdit(true)}>
                <i className='icon-pen'></i>
            </div>
            <div className='product-card-btn delete' onClick={() => setDeleteModal(true)}>
                <i className='icon-trash'></i>
            </div>
        </div>
        <div className='product-add-btn right' onClick={() => createProduct()}>
            <i className='icon-plus'></i>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug} setCartOpen={setCartOpen} openProduct={openProduct}/>}
        {showRecomended && <ProductViewModal show={showRecomended} setShow={setShowRecomended} slug={recomendedSlug} setCartOpen={setCartOpen}/>}
        {showEdit && <ProductEditModal show={showEdit} setShow={setShowEdit} card={card} fetchProducts={fetchProducts}/>}
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={card.id}
                method={CatalogServices.products.delete}
                successCallback={fetchProducts}
            />
        }
    </div>
  )
})

export default ProductEditCard