import http from "./http-common"
import {getData} from "../handlers";

export default class Dictionaries {
    static productStates = async (data) => {
        const res = await getData(http,`/dictionaries/productStates${data?.queryString ? `?${data.queryString}` : ""}`);
        return res
    }

    static productCategories = async (data) => {
        const res = await getData(http,`/dictionaries/productCategories${data?.queryString ? `?${data.queryString}` : ""}`);
        return res
    }
}